import { render, staticRenderFns } from "./SplitPDF.vue?vue&type=template&id=9390fb88&scoped=true"
import script from "./SplitPDF.vue?vue&type=script&lang=js"
export * from "./SplitPDF.vue?vue&type=script&lang=js"
import style0 from "./SplitPDF.vue?vue&type=style&index=0&id=9390fb88&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9390fb88",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9390fb88')) {
      api.createRecord('9390fb88', component.options)
    } else {
      api.reload('9390fb88', component.options)
    }
    module.hot.accept("./SplitPDF.vue?vue&type=template&id=9390fb88&scoped=true", function () {
      api.rerender('9390fb88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FinanceVer2/ByClass/SplitPDF.vue"
export default component.exports